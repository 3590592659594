import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css'
import './dashboard.scss'
import * as Yup from 'yup';
import { Formik } from 'formik';
import clsx from "clsx";
import { Card, Table as BSTable, } from 'react-bootstrap';
import { Grid } from '@material-ui/core';
import DashboardLayout from '../../layouts/dashboardLayout'
import { InputField, Navbar, AppBar } from '../../customComponents'
import { appConstants } from '../../themes/constants'
import { useStyles } from '../../styles/styles'
import { useSelector, useDispatch } from 'react-redux'
import { getPopupAction, popupUpdateAction } from '../../store/actions';
import { toast } from 'react-toastify';
import { uploadFile } from "../../utils/s3Bucket";
import fileUpload from '../../fileUploader/fileUpload';
import { getImage } from '../../fileUploader/getImage';

export const PopupManagement = () => {
    const classes = useStyles();
    const [imageValue, setImageValue] = useState('');
    const [imageValue2, setImageValue2] = useState('');
    const dispatch = useDispatch()
    const data = useSelector(state => state.popupManagement.data);
  

    useEffect(() => {
        document.title = appConstants.title
        dispatch(getPopupAction()) 
    }, [])

    const validationSchema = Yup.object().shape({
      line1          : Yup.string().required('Please enter line 1.'),
      line2          : Yup.string().required('Please enter line 2.'),
      line3          : Yup.string().required('Please enter line 3.'),     
    })
    const [offerImage1,setOfferImage1]=useState("")
    const [offerImage2,setOfferImage2]=useState("")
useEffect(()=>{
  fetchImages()
},[data])

const fetchImages= (async ()=>{
  if( data?.image1 && data?.image2){

    const image1 = await getImage(
      data?.image1,
      "offer"
      );
      const image2 = await getImage(
        data?.image2,
        "offer"
    );
    setOfferImage1(image1)
setOfferImage2(image2)
  }

})
    return (
        <DashboardLayout>
            <AppBar breadcrumbs={[{ route: '/offer-popup', name: "Popup Management" }]} />
            <Navbar title="Popup Management" />
            <Card className={classes.card}>
            <Grid container> 
            <Grid smup={12} className={classes.tableContainerRow}>
            <Formik
            enableReinitialize={true} 
            initialValues={{
              line1          : data?.line1,
              line2          : data?.line2,
              line3          : data?.line3,
              coupon         : data?.coupon,
              image1         : data?.image1, 
              image2         : data?.image2,                         
            }}
            validationSchema={validationSchema}     
            onSubmit={async (values) => {     
              if(imageValue) {
                values.image1 = await fileUpload(imageValue,false, "offer")
              }
              if(imageValue2) {
                values.image2 = await fileUpload(imageValue2,false,"offer")
              }
              dispatch(popupUpdateAction(values))
            }}     
             
            >
            {({
                values,
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
              }) => 
            
              (
                
                <form onSubmit={handleSubmit}>
                    <BSTable striped bordered>
                    <tbody>                       
                        <tr>
                        <td className={classes.rowKey}>
                          First Line
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <InputField
                              placeholder="First Line"
                              name="line1"
                              error={Boolean(touched?.line1 && errors?.line1)}
                              helperText={touched?.line1 && errors?.line1}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              value={values?.line1}
                            />
                          </div>
                        </td>
                        </tr>
                        <tr>
                        <td className={classes.rowKey}>
                          Second Line
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <InputField
                              placeholder="Second line"
                              name="line2"
                              error={Boolean(touched?.line2 && errors?.line2)}
                              helperText={touched?.line2 && errors?.line2}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              value={values?.line2}
                            />
                          </div>
                        </td>
                        </tr>
                        <tr>
                        <td className={classes.rowKey}>
                          Third Line
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <InputField
                              placeholder="Third Line"
                              name="line3"
                              error={Boolean(touched?.line3 && errors?.line3)}
                              helperText={touched?.line3 && errors?.line3}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              value={values?.line3}
                            />
                          </div>
                        </td>
                        </tr>
                        <tr>
                        <td className={classes.rowKey}>
                         Coupon Name
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <InputField
                              placeholder="Enter Coupon name"
                              name="coupon"
                              error={Boolean(touched?.coupon && errors?.coupon)}
                              helperText={touched?.coupon && errors?.coupon}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              value={values?.coupon}
                            />
                          </div>
                        </td>
                        </tr>
                        <tr>
                        <td className={classes.rowKey}>
                          First Image 
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <img src={imageValue ? URL.createObjectURL(imageValue) : offerImage1} style={{ width: 50, height: 50, objectFit: 'cover' }} />
                            <InputField
                              placeholder="Select Image"
                              name="image1"
                              onChange={e => setImageValue(e.target.files[0])}
                              type="file"
                              value={imageValue?.path}
                              accept="image/png, image/gif, image/jpeg"
                            />
                          </div>
                        </td>
                        </tr>
                        <tr>
                        <td className={classes.rowKey}>
                          Second Image 
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <img src={imageValue2 ? URL.createObjectURL(imageValue2) : offerImage2} style={{ width: 50, height: 50, objectFit: 'cover' }} />
                            <InputField
                              placeholder="Select Image"
                              name="image2"
                              onChange={e => setImageValue2(e.target.files[0])}
                              type="file"
                              value={imageValue2?.path}
                              accept="image/png, image/gif, image/jpeg"
                            />
                          </div>
                        </td>
                        </tr>
                        <tr >
                        <td className={classes.rowKey}></td>
                        <td className={classes.rowValue}>
                          <button
                            type="submit"
                            className={clsx([classes.authButton, classes.filledButton])}
                          >
                            Submit
                          </button>
                        </td>
                      </tr>

                    </tbody>
                    </BSTable>
                </form>
                )}
            </Formik>
            </Grid>
            </Grid>
            </Card>


        </DashboardLayout>


    )
}