import { Colors } from '../themes/colors'

const object = {
    subtitle1: {
        color: Colors.breadcrumb,
        fontSize: 19,
        lineHeight: 1,
    },
};
export default object;
