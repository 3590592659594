import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css'
import './dashboard.scss'
import * as Yup from 'yup';
import { Formik } from 'formik';
import clsx from "clsx";
import { Card, Table as BSTable, } from 'react-bootstrap';
import { Grid } from '@material-ui/core';
import DashboardLayout from '../../layouts/dashboardLayout'
import { InputField, Navbar, AppBar } from '../../customComponents'
import { appConstants } from '../../themes/constants'
import { useStyles } from '../../styles/styles'
import { useSelector, useDispatch } from 'react-redux'
import { HomeUpdateAction, getHomeAction, globalLoader } from '../../store/actions';
import fileUpload from '../../fileUploader/fileUpload';
import { getImage } from '../../fileUploader/getImage';


export const HomeManagement = () => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [imageValue, setImageValue] = useState('');
  const [imageValue2, setImageValue2] = useState('');
  const [imageValue3, setImageValue3] = useState('');
  const [imageValue4, setImageValue4] = useState('');
  const [imageValue5, setImageValue5] = useState('');
  const [imageValue6, setImageValue6] = useState('');
  const [videoValue, setVideoValue] = useState('');
  const [secondImage, setSecondImage] = useState('');
  const [secondImage1, setSecondImage1] = useState('');
  const [secondImage2, setSecondImage2] = useState('');
  const [thirdImage2, setThirdImage2] = useState('');
  const [fourthImage2, setFourthImage2] = useState('');
  const [fifthImage2, setFifthImage2] = useState('');
  const [sixthImage, setSixthImage] = useState('');
  const [sixthImage1, setSixthImage1] = useState('');
  const [sixthImage2, setSixthImage2] = useState('');

  const data = useSelector(state => state.homeManagement.data);

  const validationSchema = Yup.object().shape({
    instragram: Yup.string().required('Please enter instagram social link.'),
    facebook: Yup.string().required('Please enter facebook social link.'),
    youTube: Yup.string().required('Please enter youtube social link.'),
    pinterest: Yup.string().required('Please enter pinterest social link.'),
    secondContentTitle: Yup.string().required('Please enter second box title.'),
    secondContentDesc: Yup.string().required('Please enter second box description.'),
    firstContentTitle: Yup.string().required('Please enter first box title.'),
    //firstContentVideo   : Yup.string().required('Please upload first box video.'),
    firstContentDesc: Yup.string().required('Please enter first title.'),
    youTubeLink1: Yup.string().required('Please enter youtube link.'),
    youTubeLink2: Yup.string().required('Please enter youtube link.'),
    youTubeLink3: Yup.string().required('Please enter youtube link.'),
  })

  // Lifecycle Hooks
  useEffect(() => {
    document.title = appConstants.title
    dispatch(getHomeAction());
  }, [])
  const [images, setImages] = useState([])
  const [secondContentImages, setSecondContentImages] = useState([])
  const [sixthContentImages, setsixthContentImages] = useState([])
  const [thirdContentImages, setthirdContentImages] = useState([])
  const [forthContentImages, setforthContentImages] = useState("")
  const [fifthContentImage, setfifthContent] = useState("")
  const [popupImage1, setpopupImage1] = useState("")
  const [popupImage2, setpopupImage2] = useState("")
  useEffect(() => {
    if(data && Object.keys(data)?.length>0){

      getImages();
    }
  }, [data]);

  const getImages = async () => {

    const getSectionImages = async (links, setImageFunction) => {
      const sectionImages = await Promise.all(
        links?.map(async (e) => await getImage(e, "home"))
      );
      setImageFunction(sectionImages);
    };

    await getSectionImages(
      data?.firstContent?.links,
      setImages
    );

    await getSectionImages(
      data?.secondContent?.links,
      setSecondContentImages
    );

    await getSectionImages(
      data?.sixthContent?.links,
      setsixthContentImages
    );

    const thirdContent = await getImage(
      data?.thirdContent?.Image,
      "home"
    );
    setthirdContentImages(thirdContent);

    const forthContent = await getImage(
      data?.forthContent?.Image,
      "home"
    );
    setforthContentImages(forthContent);

    const fifthContent = await getImage(
      data?.fifthContent?.Image,
      "home"
    );
    setfifthContent(fifthContent);


    // const image1 = await getImage(
    //   popupData?.image1,
    //   "offer"
    // );
    // setpopupImage1(image1);
    // const image2 = await getImage(
    //   popupData?.image2,
    //   "offer"
    // );
    // setpopupImage2(image2);

  };
  return (
    <DashboardLayout>
      <AppBar breadcrumbs={[{ route: '/home', name: "Home Management" }]} />
      <Navbar title="Home Management" />
      <Card className={classes.card}>
        {/* <Box m={3} /> */}
        <Grid container>
          <Grid smUp={12} className={classes.tableContainerRow}>
            <Formik
              enableReinitialize={true}
              initialValues={{
                instragram: data?.instragram,
                facebook: data?.facebook,
                youTube: data?.youTube,
                pinterest: data?.pinterest,
                secondContentTitle: data?.secondContent?.title,
                secondContentDesc: data?.secondContent?.description,
                firstContentTitle: data?.firstContent?.title,
                firstContentDesc: data?.firstContent?.description,
                firstContentVideo: data?.firstContent?.video,
                thirdContentTitle: data?.thirdContent?.title,
                thirdContentHeading: data?.thirdContent?.heading,
                thirdContentDescription: data?.thirdContent?.description,
                thirdContentImage: data?.thirdContent?.Image,
                forthContentTitle: data?.forthContent?.title,
                forthContentHeading: data?.forthContent?.heading,
                forthContentDescription: data?.forthContent?.description,
                forthContentImage: data?.forthContent?.Image,
                fifthContentTitle: data?.fifthContent?.title,
                fifthContentHeading: data?.fifthContent?.heading,
                fifthContentDescription: data?.fifthContent?.description,
                fifthContentImage: data?.fifthContent?.Image,
                sixthContentTitle: data?.sixthContent?.title,
                sixthContentDesc: data?.sixthContent?.description,
                meta_tag: data?.meta_tag,
                meta_description: data?.meta_description,
                youTubeLink1: data?.youTubeLink1,
                youTubeLink2: data?.youTubeLink2,
                youTubeLink3: data?.youTubeLink3,
                popup: data?.popup,
              }}
              validationSchema={validationSchema}
              onSubmit={async (values) => {
                dispatch(globalLoader(true));
                const imgUrls = {
                  image1: data?.firstContent?.links[0],
                  image2: data?.firstContent?.links[1],
                  image3: data?.firstContent?.links[2],
                  image4: data?.firstContent?.links[3],
                  image5: data?.firstContent?.links[4],
                  image6: data?.firstContent?.links[5]
                }
                const secImgUrls = {
                  image1: data?.secondContent?.links[0],
                  image2: data?.secondContent?.links[1],
                  image3: data?.secondContent?.links[2],
                }

                if (imageValue) {
                  imgUrls.image1 = await fileUpload(imageValue)
                }
                if (imageValue2) {
                  imgUrls.image2 = await fileUpload(imageValue2)
                }
                if (imageValue3) {
                  imgUrls.image3 = await fileUpload(imageValue3)
                }
                if (imageValue4) {
                  imgUrls.image4 = await fileUpload(imageValue4)
                }
                if (imageValue5) {
                  imgUrls.image5 = await fileUpload(imageValue5)
                }
                if (imageValue6) {
                  imgUrls.image6 = await fileUpload(imageValue6)
                }
                if (secondImage) {
                  secImgUrls.image1 = await fileUpload(secondImage)
                }
                if (secondImage1) {
                  secImgUrls.image2 = await fileUpload(secondImage1)
                }
                if (secondImage2) {
                  secImgUrls.image3 = await fileUpload(secondImage2)
                }
                let firstVideo = data?.firstContent?.video
                if (videoValue) {
                  firstVideo = await fileUpload(videoValue)
                }

                let thImage = data?.thirdContent?.Image
                if (thirdImage2) {
                  thImage = await fileUpload(thirdImage2)
                }

                let fourImage = data?.forthContent?.Image
                if (fourthImage2) {
                  fourImage = await fileUpload(fourthImage2)
                }

                let fiveImage = data?.fifthContent?.Image
                if (fifthImage2) {
                  fiveImage = await fileUpload(fifthImage2)
                }
                const sixthImgUrls = {
                  image1: data?.sixthContent?.links[0] ?? ' ',
                  image2: data?.sixthContent?.links[1] ?? ' ',
                  image3: data?.sixthContent?.links[2] ?? ' ',
                }

                if (sixthImage) {
                  sixthImgUrls.image1 = await fileUpload(sixthImage)
                }
                if (sixthImage1) {
                  sixthImgUrls.image2 = await fileUpload(sixthImage1)
                }
                if (sixthImage2) {
                  sixthImgUrls.image3 = await fileUpload(sixthImage2)
                }

                const tempData = {
                  "instragram": values?.instragram,
                  "twitter": values?.instragram,
                  "facebook": values?.facebook,
                  "pinterest": values?.pinterest,
                  "youTube": values?.youTube,
                  "meta_description": values.meta_description,
                  "meta_tag": values.meta_tag,
                  "youTubeLink1": values.youTubeLink1,
                  "youTubeLink2": values.youTubeLink2,
                  "youTubeLink3": values.youTubeLink3,
                  "firstContent": {
                    "description": values?.firstContentDesc,
                    "title": values?.firstContentTitle,
                    "video": firstVideo,
                    "links": [
                      imgUrls.image1,
                      imgUrls.image2,
                      imgUrls.image3,
                      imgUrls.image4,
                      imgUrls.image5,
                      imgUrls.image6,
                    ]
                  },
                  "secondContent": {
                    "description": values?.secondContentDesc,
                    "title": values?.secondContentTitle,
                    "links": [
                      secImgUrls.image1,
                      secImgUrls.image2,
                      secImgUrls.image3
                    ]
                  },
                  "thirdContent": {
                    "title": values?.thirdContentTitle,
                    "heading": values?.thirdContentHeading,
                    "description": values?.thirdContentDescription,
                    "Image": thImage
                  },
                  "forthContent": {
                    "title": values?.forthContentTitle,
                    "heading": values?.forthContentHeading,
                    "description": values?.forthContentDescription,
                    "Image": fourImage
                  },
                  "fifthContent": {
                    "title": values?.fifthContentTitle,
                    "heading": values?.fifthContentHeading,
                    "description": values?.fifthContentDescription,
                    "Image": fiveImage
                  },
                  "sixthContent": {
                    "title": values?.sixthContentTitle,
                    "description": values?.sixthContentDesc,
                    "links": [
                      sixthImgUrls.image1,
                      sixthImgUrls.image2,
                      sixthImgUrls.image3
                    ]
                  },
                  "popup": values?.popup

                }
                dispatch(HomeUpdateAction(tempData))
              }}
            >
              {({
                values,
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <BSTable striped bordered>
                    <tbody>
                      <tr>
                        <td className={classes.rowKey}>
                          First Box Title
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <InputField
                              placeholder="First Box Title"
                              name="firstContentTitle"
                              error={Boolean(touched.firstContentTitle && errors.firstContentTitle)}
                              helperText={touched.firstContentTitle && errors.firstContentTitle}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              style={{ width: '50vw' }}
                              value={values.firstContentTitle}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>
                          First Box Description
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <InputField
                              placeholder="First Box Description"
                              name="firstContentDesc"
                              error={Boolean(touched.firstContentDesc && errors.firstContentDesc)}
                              helperText={touched.firstContentDesc && errors.firstContentDesc}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              style={{ width: '50vw' }}
                              value={values.firstContentDesc}
                              textArea
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>
                          First Box Image Link 1
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper} style={{ display: 'flex', gap: 12 }}>
                            <img src={imageValue ? URL.createObjectURL(imageValue) : images?.[0]} style={{ width: 50, height: 50, objectFit: 'cover' }} />
                            <InputField
                              placeholder="Select Image"
                              name="image"
                              onChange={e => setImageValue(e.target.files[0])}
                              type="file"
                              value={imageValue?.path}
                              accept="image/png, image/gif, image/jpeg"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>
                          First Box Image Link 2
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper} style={{ display: 'flex', gap: 12 }}>
                            <img src={imageValue2 ? URL.createObjectURL(imageValue2) : images?.[1]} style={{ width: 50, height: 50, objectFit: 'cover' }} />
                            <InputField
                              placeholder="Select Image"
                              name="image"
                              onChange={e => setImageValue2(e.target.files[0])}
                              type="file"
                              value={imageValue2?.path}
                              accept="image/png, image/gif, image/jpeg"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>
                          First Box Image Link 3
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper} style={{ display: 'flex', gap: 12 }}>
                            <img src={imageValue3 ? URL.createObjectURL(imageValue3) : images?.[2]} style={{ width: 50, height: 50, objectFit: 'cover' }} />
                            <InputField
                              placeholder="Select Image"
                              name="image"
                              onChange={e => setImageValue3(e.target.files[0])}
                              type="file"
                              value={imageValue3?.path}
                              accept="image/png, image/gif, image/jpeg"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>
                          First Box Image Link 4
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper} style={{ display: 'flex', gap: 12 }}>
                            <img src={imageValue4 ? URL.createObjectURL(imageValue4) : images?.[3]} style={{ width: 50, height: 50, objectFit: 'cover' }} />
                            <InputField
                              placeholder="Select Image"
                              name="image"
                              onChange={e => setImageValue4(e.target.files[0])}
                              type="file"
                              value={imageValue4?.path}
                              accept="image/png, image/gif, image/jpeg"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>
                          First Box Image Link 5
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper} style={{ display: 'flex', gap: 12 }}>
                            <img src={imageValue5 ? URL.createObjectURL(imageValue5) : images?.[4]} style={{ width: 50, height: 50, objectFit: 'cover' }} />
                            <InputField
                              placeholder="Select Image"
                              name="image"
                              onChange={e => setImageValue5(e.target.files[0])}
                              type="file"
                              value={imageValue5?.path}
                              accept="image/png, image/gif, image/jpeg"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>
                          First Box Image Link 6
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper} style={{ display: 'flex', gap: 12 }}>
                            <img src={imageValue6 ? URL.createObjectURL(imageValue6) : images?.[5]} style={{ width: 50, height: 50, objectFit: 'cover' }} />
                            <InputField
                              placeholder="Select Image"
                              name="image"
                              onChange={e => setImageValue6(e.target.files[0])}
                              type="file"
                              value={imageValue6?.path}
                              accept="image/png, image/gif, image/jpeg"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>
                          First Box video
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper} style={{ display: 'flex', gap: 12 }}>
                            <video controls width="50px ">
                              <source src={data?.firstContent?.video} type="video/mp4" />
                            </video>
                            <InputField
                              placeholder="Select Image"
                              name="video"
                              onChange={e => setVideoValue(e.target.files[0])}
                              type="file"
                              value={imageValue6?.path}
                              accept="image/png, image/gif, image/jpeg"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>
                          Second Box Title
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <InputField
                              placeholder="Second Box Title"
                              name="secondContentTitle"
                              error={Boolean(touched.secondContentTitle && errors.secondContentTitle)}
                              helperText={touched.secondContentTitle && errors.secondContentTitle}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              style={{ width: '50vw' }}
                              value={values.secondContentTitle}
                              textArea
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>
                          Second Box Description
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <InputField
                              placeholder="Second Box Description"
                              name="secondContentDesc"
                              error={Boolean(touched.secondContentDesc && errors.secondContentDesc)}
                              helperText={touched.secondContentDesc && errors.secondContentDesc}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              style={{ width: '50vw' }}
                              value={values.secondContentDesc}
                              textArea
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>
                          Second Box Image 8 frames (4x2)
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper} style={{ display: 'flex', gap: 12 }}>
                            <img src={secondImage ? URL.createObjectURL(secondImage) : secondContentImages?.[0]} style={{ width: 50, height: 50, objectFit: 'cover' }} />
                            <InputField
                              placeholder="Select Image"
                              name="image"
                              onChange={e => setSecondImage(e.target.files[0])}
                              type="file"
                              value={secondImage?.path}
                              accept="image/png, image/gif, image/jpeg"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>
                          Second Box Image 12 frames (4x3)
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper} style={{ display: 'flex', gap: 12 }}>
                            <img src={secondImage1 ? URL.createObjectURL(secondImage1) : secondContentImages?.[1]} style={{ width: 50, height: 50, objectFit: 'cover' }} />
                            <InputField
                              placeholder="Select Image"
                              name="image"
                              onChange={e => setSecondImage1(e.target.files[0])}
                              type="file"
                              value={secondImage1?.path}
                              accept="image/png, image/gif, image/jpeg"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>
                          Second Box Image 16 Frames (4x4)
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper} style={{ display: 'flex', gap: 12 }}>
                            <img src={secondImage2 ? URL.createObjectURL(secondImage2) : secondContentImages?.[2]} style={{ width: 50, height: 50, objectFit: 'cover' }} />
                            <InputField
                              placeholder="Select Image"
                              name="image"
                              onChange={e => setSecondImage2(e.target.files[0])}
                              type="file"
                              value={secondImage2?.path}
                              accept="image/png, image/gif, image/jpeg"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>
                          Third Box Title
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <InputField
                              placeholder="Third Box Title"
                              name="thirdContentTitle"
                              error={Boolean(touched.thirdContentTitle && errors.thirdContentTitle)}
                              helperText={touched.thirdContentTitle && errors.thirdContentTitle}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              style={{ width: '50vw' }}
                              value={values.thirdContentTitle}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>
                          Third Box Heading
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <InputField
                              placeholder="Third Box Heading"
                              name="thirdContentHeading"
                              error={Boolean(touched.thirdContentHeading && errors.thirdContentHeading)}
                              helperText={touched.thirdContentHeading && errors.thirdContentHeading}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              style={{ width: '50vw' }}
                              value={values.thirdContentHeading}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>
                          Third Box Description
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <InputField
                              placeholder="Third Box Description"
                              name="thirdContentDescription"
                              error={Boolean(touched.thirdContentDescription && errors.thirdContentDescription)}
                              helperText={touched.thirdContentDescription && errors.thirdContentDescription}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              style={{ width: '50vw' }}
                              value={values.thirdContentDescription}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>
                          Third Box Image Link
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper} style={{ display: 'flex', gap: 12 }}>
                            <img src={thirdImage2 ? URL.createObjectURL(thirdImage2) : thirdContentImages} style={{ width: 50, height: 50, objectFit: 'cover' }} />
                            <InputField
                              placeholder="Select Image"
                              name="image"
                              onChange={e => setThirdImage2(e.target.files[0])}
                              type="file"
                              value={thirdImage2?.path}
                              accept="image/png, image/gif, image/jpeg"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>
                          Fourth Box Title
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <InputField
                              placeholder="Fourth Box Title"
                              name="forthContentTitle"
                              error={Boolean(touched.forthContentTitle && errors.forthContentTitle)}
                              helperText={touched.forthContentTitle && errors.forthContentTitle}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              style={{ width: '50vw' }}
                              value={values.forthContentTitle}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>
                          Fourth Box Heading
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <InputField
                              placeholder="Fourth Box Title"
                              name="forthContentHeading"
                              error={Boolean(touched.forthContentHeading && errors.forthContentHeading)}
                              helperText={touched.forthContentHeading && errors.forthContentHeading}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              style={{ width: '50vw' }}
                              value={values.forthContentHeading}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>
                          Fourth Box Description
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <InputField
                              placeholder="Fourth Box Title"
                              name="forthContentDescription"
                              error={Boolean(touched.forthContentDescription && errors.forthContentDescription)}
                              helperText={touched.forthContentDescription && errors.forthContentDescription}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              style={{ width: '50vw' }}
                              value={values.forthContentDescription}
                            />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className={classes.rowKey}>
                          Fourth Box Image Link
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper} style={{ display: 'flex', gap: 12 }}>
                            <img src={fourthImage2 ? URL.createObjectURL(fourthImage2) : forthContentImages} style={{ width: 50, height: 50, objectFit: 'cover' }} />
                            <InputField
                              placeholder="Select Image"
                              name="image"
                              onChange={e => setFourthImage2(e.target.files[0])}
                              type="file"
                              value={fourthImage2?.path}
                              accept="image/png, image/gif, image/jpeg"
                            />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className={classes.rowKey}>
                          Fifth Box Title
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <InputField
                              placeholder="Second Box Title"
                              name="fifthContentTitle"
                              error={Boolean(touched.fifthContentTitle && errors.fifthContentTitle)}
                              helperText={touched.fifthContentTitle && errors.fifthContentTitle}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              style={{ width: '50vw' }}
                              value={values.fifthContentTitle}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>
                          Fifth Box Heading
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <InputField
                              placeholder="Fifth Box Heading"
                              name="fifthContentHeading"
                              error={Boolean(touched.fifthContentHeading && errors.fifthContentHeading)}
                              helperText={touched.fifthContentHeading && errors.fifthContentHeading}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              style={{ width: '50vw' }}
                              value={values.fifthContentHeading}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>
                          Fifth Box Description
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <InputField
                              placeholder="Fifth Box Description"
                              name="fifthContentDescription"
                              error={Boolean(touched.fifthContentDescription && errors.fifthContentDescription)}
                              helperText={touched.fifthContentDescription && errors.fifthContentDescription}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              style={{ width: '50vw' }}
                              value={values.fifthContentDescription}
                            />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className={classes.rowKey}>
                          Fifth Box Image Link
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper} style={{ display: 'flex', gap: 12 }}>
                            <img src={fifthImage2 ? URL.createObjectURL(fifthImage2) : fifthContentImage} style={{ width: 50, height: 50, objectFit: 'cover' }} />
                            <InputField
                              placeholder="Select Image"
                              name="image"
                              onChange={e => setFifthImage2(e.target.files[0])}
                              type="file"
                              value={fifthImage2?.path}
                              accept="image/png, image/gif, image/jpeg"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>
                          Sixth Box Title
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <InputField
                              placeholder="Sixth Box Title"
                              name="sixthContentTitle"
                              error={Boolean(touched.sixthContentTitle && errors.sixthContentTitle)}
                              helperText={touched.sixthContentTitle && errors.sixthContentTitle}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              style={{ width: '50vw' }}
                              value={values.sixthContentTitle}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>
                          Sixth Box Description
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <InputField
                              placeholder="Sixth Section Description"
                              name="sixthContentDesc"
                              error={Boolean(touched.sixthContentDesc && errors.sixthContentDesc)}
                              helperText={touched.sixthContentDesc && errors.sixthContentDesc}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              style={{ width: '50vw' }}
                              value={values.sixthContentDesc}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>
                          Sixth Box Image Link 1
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper} style={{ display: 'flex', gap: 12 }}>
                            <img src={sixthImage ? URL.createObjectURL(sixthImage) : sixthContentImages?.[0]} style={{ width: 50, height: 50, objectFit: 'cover' }} />
                            <InputField
                              placeholder="Select Image"
                              name="image"
                              onChange={e => setSixthImage(e.target.files[0])}
                              type="file"
                              value={sixthImage?.path}
                              accept="image/png, image/gif, image/jpeg"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>
                          Sixth Box Image Link 2
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper} style={{ display: 'flex', gap: 12 }}>
                            <img src={sixthImage1 ? URL.createObjectURL(sixthImage1) : sixthContentImages?.[1]} style={{ width: 50, height: 50, objectFit: 'cover' }} />
                            <InputField
                              placeholder="Select Image"
                              name="image"
                              onChange={e => setSixthImage1(e.target.files[0])}
                              type="file"
                              value={sixthImage1?.path}
                              accept="image/png, image/gif, image/jpeg"
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>
                          Sixth Box Image Link 3
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper} style={{ display: 'flex', gap: 12 }}>
                            <img src={sixthImage2 ? URL.createObjectURL(sixthImage2) : sixthContentImages?.[2]} style={{ width: 50, height: 50, objectFit: 'cover' }} />
                            <InputField
                              placeholder="Select Image"
                              name="image"
                              onChange={e => setSixthImage2(e.target.files[0])}
                              type="file"
                              value={sixthImage2?.path}
                              accept="image/png, image/gif, image/jpeg"
                            />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className={classes.rowKey}>
                          Instragram Link
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <InputField
                              placeholder="Instragram Link"
                              name="instragram"
                              error={Boolean(touched.instragram && errors.instragram)}
                              helperText={touched.instragram && errors.instragram}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              value={values.instragram}
                              style={{ width: '50vw' }}
                            />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className={classes.rowKey}>
                          Facebook Link
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <InputField
                              placeholder="Facebook Link"
                              name="facebook"
                              error={Boolean(touched.facebook && errors.facebook)}
                              helperText={touched.facebook && errors.facebook}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              style={{ width: '50vw' }}
                              value={values.facebook}
                            />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className={classes.rowKey}>
                          Youtube Link
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <InputField
                              placeholder="Youtube Link"
                              name="youTube"
                              error={Boolean(touched.youTube && errors.youTube)}
                              helperText={touched.youTube && errors.youTube}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              style={{ width: '50vw' }}
                              value={values.youTube}
                            />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className={classes.rowKey}>
                          Pinterest Link
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <InputField
                              placeholder="Pinterest Link"
                              name="pinterest"
                              error={Boolean(touched.pinterest && errors.pinterest)}
                              helperText={touched.pinterest && errors.pinterest}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              style={{ width: '50vw' }}
                              value={values.pinterest}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>
                          Meta Title
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <InputField
                              placeholder="Enter Meta Title"
                              name="meta_tag"
                              error={Boolean(touched.meta_tag && errors.meta_tag)}
                              helperText={touched.meta_tag && errors.meta_tag}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              style={{ width: '50vw' }}
                              value={values.meta_tag}
                            />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className={classes.rowKey}>
                          Meta Description
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <InputField
                              placeholder="Enter Meta Description"
                              name="meta_description"
                              error={Boolean(touched.meta_description && errors.meta_description)}
                              helperText={touched.meta_description && errors.meta_description}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              style={{ width: '50vw' }}
                              value={values.meta_description}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>
                          Youtube Video Link
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <InputField
                              placeholder="First Youtube Link"
                              name="youTubeLink1"
                              error={Boolean(touched.youTubeLink1 && errors.youTubeLink1)}
                              helperText={touched.youTubeLink1 && errors.youTubeLink1}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              value={values.youTubeLink1}
                              style={{ width: '50vw' }}
                            />
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td className={classes.rowKey}>
                          Youtube Video Link
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <InputField
                              style={{ width: '50vw' }}
                              placeholder="Second Youtube Link"
                              name="youTubeLink2"
                              error={Boolean(touched.youTubeLink2 && errors.youTubeLink2)}
                              helperText={touched.youTubeLink2 && errors.youTubeLink2}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              value={values.youTubeLink2}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>
                          Youtube Video Link
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <InputField
                              placeholder="Third Youtube Link"
                              name="youTubeLink3"
                              error={Boolean(touched.youTubeLink3 && errors.youTubeLink3)}
                              helperText={touched.youTubeLink3 && errors.youTubeLink3}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              style={{ width: '50vw' }}
                              type="text"
                              value={values.youTubeLink3}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>
                          Show Popup On Home Screen
                        </td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <select
                              name="popup"
                              placeholder="Show Popup"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className='form-control'
                              value={values.popup}
                              style={{ width: '50vw' }}
                            >
                              <option value="">Select Options</option>
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                        </td>
                      </tr>

                      {/* <tr>
                          <td className={classes.rowKey}>
                            First Box Youtube Link 4
                          </td>
                          <td className={classes.rowValue}>
                            <div className={classes.inputWrapper}>
                              <InputField
                                placeholder="First Box Youtube Link 4"
                                name="firstContentYoutube4"
                                error={Boolean(touched.firstContentYoutube4 && errors.firstContentYoutube4)}
                                helperText={touched.firstContentYoutube4 && errors.firstContentYoutube4}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="text"
                                style={{ width: '50vw' }}
                                value={values.firstContentYoutube4}
                              />
                            </div>
                          </td>
                        </tr> */}



                      <tr>
                        <td className={classes.rowKey}></td>
                        <td className={classes.rowValue}>
                          <button
                            type="submit"
                            className={clsx([classes.authButton, classes.filledButton])}
                          >
                            Submit
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </BSTable>
                </form>
              )}
            </Formik>


          </Grid>
        </Grid>
      </Card>
    </DashboardLayout>
  );
}
