import React, { useState, useEffect } from 'react';
import moment from 'moment'
// Navigation
import { useHistory } from 'react-router-dom'
// Styles
import 'antd/dist/antd.css'
import './dashboard.scss'
// antd
import { Table, Pagination } from 'antd';
import { Card, } from 'react-bootstrap';
import { Grid, useTheme, useMediaQuery, Box } from '@material-ui/core';
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { AppBar, Button, PaginationBar, Navbar, NavbarSearch } from '../../customComponents'
// Constants
import { appConstants } from '../../themes/constants'
// utils methods
import { useStyles } from '../../styles/styles'
import { useSelector, useDispatch } from 'react-redux'
import { userDeleteInitiate, userListInitiate } from '../../store/actions'
import { randomColor, capitalizeFirstLetter } from '../../store/helpers/helpers';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import UserEdiit from '../user/edit';
import axios from 'axios';
import { base_URL } from "../../apiKit/apikit";
import clsx from "clsx";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';



export const UserManagement = () => {
    const styleProps = { searchable: true }
    const classes = useStyles(styleProps);
    const theme = useTheme();
    const dispatch = useDispatch()
    const userList = useSelector(x => x.userManagement.userList)   
    const pagination = useSelector(x => x.userManagement.pagination)
    const [payload, setPayload] = useState({ offset: 0, limit: 10, sort: "", order: 0, search: "" })
    const [currentPage, setCurrentPage] = useState(1)
    const [show, setShow] = useState(false);
    const matches = useMediaQuery(theme.breakpoints.down('xs')); 
    const [user, setUser] = useState({})  
    const [ list, setList ] = useState(false)
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleStartDateChange = (date) => {
      setStartDate(date);
    };
  
    const handleEndDateChange = (date) => {
      setEndDate(date);
    };

    const handleClose = () => setShow(false);

    const handleShowUser = (userInfo) => {
        setUser(userInfo)
        setShow(true)
    }

    const handleDeleteUser = (id) => {

        const data = {
            id : id
        }
        dispatch(userDeleteInitiate(data))
        setTimeout( () => {
            setList(!list)
        }, 2500)
    }
    
    useEffect(() => {
        document.title = appConstants.title
        dispatch(userListInitiate(payload))
    }, [list])

    
    const handleChange = (pagination, filters, sorter) => {
        const tempPayload = { ...payload, sort: sorter?.columnKey, order: sorter.order === "ascend" ? 1 : sorter.order === "descend" ? -1 : null, offset: currentPage === 1 ? 0 : (currentPage - 1) * 10 }
        setPayload(tempPayload)
        dispatch(userListInitiate(tempPayload))
    }  
 
    const handleSearch = (e) => {
        if (!e.target.value.startsWith(" ")) {
            const tempPayload = { ...payload, search: e.target.value.trimLeft() }
            setPayload(tempPayload)
            dispatch(userListInitiate(tempPayload))
        }
        else e.target.value = ""
    }

    const handlePagination = (pagination) => {
        setCurrentPage(pagination)
        const tempPayload = { ...payload, offset: pagination == 1 ? 0 : (pagination - 1) * 10 }
        setPayload(tempPayload)
        dispatch(userListInitiate(tempPayload))
        window.scrollTo(0, 0)
    }

     
    
    const columns = [
        {
            title: 'Sr. No.',
            dataIndex: 'sr',
            key: 'sr',
            ellipsis: false,
            width: "80px",
            render: (key, data, index) => (<>{payload.offset + index + 1}</>),
        },       
        {
            title: 'Image',
            dataIndex: 'picture',
            key: 'picture',
            sorter: true,
            render: (key, data, index) => (<>{ data.profile ? <img style={{ height: 50, width: 50 }} src={data?.profile} />  : <div className='profile-img' style={{backgroundColor : randomColor()}}> { data?.name[0].toUpperCase() }</div> }</>),
        },
        {
            title: 'Email Address',
            dataIndex: 'email',
            key: 'email',
            sorter: true,
            ellipsis: true,
            render: (key, data, index) => { return (<><span>{data?.email ? data?.email : "No Email"}</span></>) }
        },
        {
            title: 'Login Type',
            dataIndex: 'loginType',
            key: 'loginType',
            sorter: true,
            ellipsis: true,
            render: (key, data, index) => { return (<><span className={`badge bg-danger text-white`}>{ capitalizeFirstLetter(data?.loginType) }</span></>) }
        }, 
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            ellipsis: false,
            ellipsis: true
        },
        {
            title: 'Phone',
            dataIndex: 'number',
            key: 'number',
            sorter: true,
            ellipsis: false,
            ellipsis: true,
             render: (key, data) => (<>{data.number ? data?.number : "--"}</>)
        },
         {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            sorter: true,
            ellipsis: false,
            ellipsis: true,
            render: (key, data) => (<>
                <div style={{textWrap : "wrap"}}>
                    <strong>City :</strong> {data?.address?.city ?? "--"}, <br></br>
                    <strong>Pincode :</strong> {data?.address?.pincode ?? "--"}, <br></br>
                    <strong>State : </strong>{data?.address?.state ?? "--"}, <br></br>
                    <strong>Country :</strong> {data?.address?.country ?? "--"}, <br></br>
                    <strong>Street  :</strong> {data?.address?.street ?? "--"} <br></br>
                </div>
            
            </>)
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: true,
            ellipsis: false,
            render: (key, data) => (<>{data.createdAt ? moment(data.createdAt).format("DD-MM-YYYY") : "N/A"}</>)
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'Action',
            align: 'center',
            render: (key, data) => {
                return (
                    <Grid className={classes.buttons}>                                             
                        <button className="table-action-button" onClick={() => handleShowUser(data)}>
                            <EditIcon ></EditIcon>
                        </button>
                        <button className="table-action-button" onClick={() => handleDeleteUser(data?._id)}>
                            <DeleteIcon></DeleteIcon>
                        </button>
                    </Grid>
                )
            },
        },
    ];

     const handleDownload = async () => {
        const Payload = {
            startDate, endDate
        }
        
        try {
          const response = await axios.post(`${base_URL}/admin/export-user`, {Payload}, {
            responseType: 'blob', 
          });  
                     
          const blob = new Blob([response.data], { type: 'text/csv' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'users.csv';
          link.click();
        } catch (error) {
          console.error('Error downloading data:', error);
        }
      };

    return (
        <>
        <DashboardLayout>
            <AppBar breadcrumbs={[{ route: '/users', name: "User Management" }]} />
            <Grid container className="mt-4" >
                <Navbar title="User Management" searchable onChange={handleSearch} value={payload.search}
                />
                <Card className={classes.card}>
                    {matches &&
                        <Box className={classes.searchBox}>
                            <NavbarSearch />
                        </Box>
                    }
                    <Grid container>
                        <Grid smUp={12} className={classes.tableContainerRow}>
                             <div className='main_div d-flex align-items-center' style={{gap:'0 10px'}}>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={startDate}
                                className={'form-control'}
                                onChange={handleStartDateChange}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText="Start Date"
                            />
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={endDate}
                                className={'form-control'}
                                onChange={handleEndDateChange}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                placeholderText="End Date"
                            />
                            <button className={'m-0'+clsx([classes.authButton, classes.filledButton])} onClick={handleDownload} style={{marginBottom: '3px'}} >
                                Export Data
                            </button>
                            </div>
                            <Table className={classes.tableWrapper} scroll bordered columns={columns} dataSource={userList} onChange={handleChange} />
                            <Pagination current={currentPage} total={pagination?.totalCount ? pagination.totalCount : 10} className="table-pagination" onChange={handlePagination} />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </DashboardLayout>
        <UserEdiit  show={show} setShow={setShow} handleClose={handleClose} user={user} setList={setList} list={list}/>
        </>
    );
}
