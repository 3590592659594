import types from "../types/index";
const initialState = {
    isLoading: false,
    orderList: [],
    trashOrderList : [],
    pagination: {},
};

export const orderManagementReducer = (state = initialState, action) => {
    switch (action.type) {

        // category list
        case types.Api_ORDER_LIST_INITIATE: return { ...state, isLoading: true };
      
        // Success
        case types.Api_ORDER_LIST_SUCCESS: return  { ...state, isLoading: false,orderList:action?.result?.data?.list, pagination:action.result.data.pagination };

        case types.Api_ORDER_TRASH_LIST_INITIATE : return { ...state, isLoading : true};

        case types.Api_ORDER_TRASH_LIST_SUCCESS : return { ...state, isLoading : false, trashOrderList:action?.result?.data?.list, pagination:action.result.data.pagination};

        //Category management Failed Case

        //Default case
        default: return { ...state };
    }
};
