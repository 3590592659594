import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux'
import { userUpdateInitiate } from '../../store/actions';


const UserEdiit = ( { show, setShow, handleClose, user, setList, list} ) => {
    const dispatch = useDispatch()

    const validationSchema = Yup.object().shape({
      name          : Yup.string().required('Please enter the name'),
    })
  
    return (
      <>         
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit User</Modal.Title>
          </Modal.Header>
          <Modal.Body>     
            <Formik
              enableReinitialize={true}
              initialValues={{
                name              : user?.name,
                id                : user?._id,
              }}
              validationSchema={validationSchema}
              onSubmit={async (values) =>  {
                dispatch(userUpdateInitiate(values))
                setTimeout( () => {
                  setList(!list)
                  setShow(!show)
                },2000)                
              }}
            >
              {({
                values,
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                      type="text"
                      placeholder="Edit Name"
                      autoFocus
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value = {values?.name}
                  />
                </Form.Group>
                  {errors.password && touched.password && errors.password}
                  <Button type='submit' className='mr-2 btn-action'>Update</Button>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>                  
                </Form>
              )}
            </Formik>      
          </Modal.Body>
        </Modal>       
      </>
    );
  }
  
  export default UserEdiit;