import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux'
import { editCouponAction} from '../../store/actions';
import moment from 'moment'



const EditCoupon = ({edit, setEdit ,list, setList, coupon}) => {
    const dispatch = useDispatch()
    const validationSchema = Yup.object().shape({
        code          : Yup.string().required('Please enter the name'),
    })

    return (
        <>
          <Modal
          show={edit}
          onHide={() => setEdit(false)}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Coupon</Modal.Title>
          </Modal.Header>
          <Modal.Body>     
            <Formik
              enableReinitialize={true}
              initialValues={{
                id                  : coupon?._id,
                discountType        : coupon?.discountType,
                discountAmount      : coupon?.discountAmount,
                code                : coupon?.code,
                startDate           : coupon?.startDate,
                endDate             : coupon?.endDate,
                minimumAmount       : coupon?.minimumAmount,
                isShowTopBar        : coupon?.isShowTopBar,
                status              : coupon?.status,
                oneTimeUsed         : coupon?.oneTimeUsed
              }}
              validationSchema={validationSchema}
              onSubmit={async (values) =>  {
                console.log(values)
                dispatch(editCouponAction(values))
                setTimeout( () => {
                  setList(!list)
                  setEdit(!edit)
                },2000)                
              }}
            >
              {({
                values,
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
              }) => (
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Discount Type</Form.Label> 
                        <Form.Control as="select" name="discountType" value={values?.discountType} onChange={handleChange} onBlur={handleBlur}>
                            <option value="fixed" selected = { values?.discountType == 'fixed' ? true : false} >Fixed</option>
                            <option value="percentage" selected = { values?.discountType == 'percentage' ? true : false}>Percentage</option>
                        </Form.Control>   
                        <Form.Label>One time Usage</Form.Label> 
                        <Form.Control as="select" name="oneTimeUsed" value={values?.oneTimeUsed}  onChange={handleChange} onBlur={handleBlur}>                        
                            <option value="0" selected = { values?.oneTimeUsed == '0' ? true : false}>No</option>                            
                            <option value="1" selected = { values?.oneTimeUsed == '1' ? true : false}>Yes</option>
                        </Form.Control> 
                        <Form.Label>Discount Amount</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Amount"
                            autoFocus
                            name="discountAmount"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value = {values?.discountAmount}
                        />
                        <Form.Label>Minimum Amount</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Minimum Amount"
                            autoFocus
                            name="minimumAmount"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value = {values?.minimumAmount}
                        />
                        <Form.Label>Coupon Code</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Coupon Code"
                            autoFocus
                            name="code"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value = {values?.code}
                        />      
                        <Form.Label>Coupon Start From</Form.Label>
                        <Form.Control
                            type="date"
                            placeholder="Coupon Start Date"
                            autoFocus
                            name="startDate"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value = {moment(values?.startDate).format('YYYY-MM-DD')}
                        /> 
                        <Form.Label>Coupon End Date</Form.Label>
                        <Form.Control
                            type="date"
                            placeholder="Coupon End Date"
                            autoFocus
                            name="endDate"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value = {moment(values?.endDate).format('YYYY-MM-DD')}
                        />            
                        <Form.Label>Is Show Top Bar</Form.Label> 
                        <Form.Control as="select" name="isShowTopBar" value={values?.isShowTopBar}  onChange={handleChange} onBlur={handleBlur}>                        
                            <option value="0" selected = { values?.discountType == '0' ? true : false}>No</option>
                            <option value="1" selected = { values?.discountType == '1' ? true : false}>Yes</option>
                        </Form.Control> 
                        <Form.Label>Status</Form.Label> 
                        <Form.Control as="select"  name="status" value={values?.status}  onChange={handleChange} onBlur={handleBlur}>                        
                            <option value="1"  selected = { values?.status == '1' ? true : false}>Active</option>
                            <option value="0" selected = { values?.status == '0' ? true : false}>Deactive</option>
                        </Form.Control> 
                    </Form.Group>                
                  <Button type='submit' className='mr-2 btn-action'>Update</Button>
                  <Button variant="secondary" onClick={() => setEdit(false)}>
                    Close
                  </Button>                  
                </Form>
              )}
            </Formik>      
          </Modal.Body>
        </Modal> 
        </>
    )

}
export default EditCoupon;
