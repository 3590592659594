import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../layouts/dashboardLayout'
import { AppBar, Button, PaginationBar, Navbar, NavbarSearch, InputField } from '../../customComponents'
import { useStyles } from '../../styles/styles'
import { Card, Table as BSTable, } from 'react-bootstrap';
import { Grid, useTheme, useMediaQuery, Box } from '@material-ui/core';
import { Table, Pagination, Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { getCouponAction, deleteCouponAction } from '../../store/actions';
import moment from 'moment'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCoupon from '../coupon/add';
import EditCoupon from '../coupon/edit';
export const CouponManagement = () => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const couponList = useSelector(state => state.couponManagement.data);
    // const pagination = useSelector(x => x.couponManagement.pagination)
    const dispatch = useDispatch()
    const [payload, setPayload] = useState({ offset: 0, limit: 10, sort: "", order: 0, search: "" })
    const [currentPage, setCurrentPage] = useState(1)
    const [save, setSave] = useState(false)
    const [list, setList] = useState(false)
    const [coupon, setCoupon] = useState({})
    const [edit, setEdit] = useState(false)
    const [firstIndex, setFirstIndex] = useState(0)
    const [lastIndex, setLastIndex] = useState(10)
    const [allFilterCoupons, setAllFilterCoupons] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [filterCoupons, setFilterCoupons] = useState([])

    useEffect(() => {
        dispatch(getCouponAction(payload))
    }, [list])

    const handleChange = (pagination, filters, sorter) => {
        debugger
        const tempPayload = { ...payload, sort: sorter?.columnKey, order: sorter.order === "ascend" ? 1 : sorter.order === "descend" ? -1 : null, offset: currentPage === 1 ? 0 : (currentPage - 1) * 10 }
        setPayload(tempPayload)
        dispatch(getCouponAction(tempPayload))
    }

    const handleEditUser = (data) => {
        setCoupon(data)
        setEdit(true)
    }

    const handleDeleteUser = (id) => {
        const payload = {
            id: id
        }
        dispatch(deleteCouponAction(payload))
        setTimeout(() => {
            setList(!list)

        }, 2000)
    }

    const handlePagination = (pagination) => {
        debugger
        setCurrentPage(pagination)
        setFirstIndex(10 * pagination - 10)
        setLastIndex(10 * pagination)
        // const tempPayload = { ...payload, offset: pagination == 1 ? 0 : (pagination - 1) * 10 }
        // setPayload(tempPayload)
        // dispatch(getCouponAction(tempPayload))
        window.scrollTo(0, 0)
    }

    const handleClose = () => {
        setSave(false)
    }
    useEffect(() => {
        console.log(couponList, "couponList");
        setTotalCount(couponList?.length)
        setAllFilterCoupons(couponList?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)))
        setFilterCoupons(couponList?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            ?.slice(firstIndex, lastIndex))
    }, [couponList])
    useEffect(() => {
        setFilterCoupons(allFilterCoupons?.slice(firstIndex, lastIndex))

    }, [lastIndex, firstIndex])

    const columns = [
        {
            title: 'Sr. No.',
            dataIndex: '_id',
            key: '_id',
            ellipsis: false,
            width: "80px",
            render: (key, data, index) => (<>{firstIndex + index + 1}</>),
        },
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            sorter: true,
        },
        {
            title: 'Discout Type',
            dataIndex: 'discountType',
            key: 'discountType',
            sorter: true,
            render: (key, data, index) => { return (<><span className='text-capitalize badge badge-danger'>{data.discountType}</span></>) }
        },
        {
            title: 'Discout Amount',
            dataIndex: 'discountAmount',
            key: 'discountAmount',
            sorter: true,
        },
        {
            title: 'Show Top Bar',
            dataIndex: 'isShowTopBar',
            key: 'isShowTopBar',
            sorter: true,
            render: (key, data, index) => { return (<><span className='text-capitalize badge badge-success'>{data?.isShowTopBar ? 'Yes' : 'No'}</span></>) }
        },
        {
            title: 'Usage',
            dataIndex: 'oneTimeUsed',
            key: 'oneTimeUsed',
            sorter: true,
            render: (key, data, index) => { return (<>{data?.oneTimeUsed ? <span className='text-capitalize badge badge-warning'>One Time</span> : <span className='text-capitalize badge badge-secondary'>Unlimited   </span>}</>) }
        },
        {
            title: 'Validity',
            dataIndex: 'discountAmount',
            key: 'discountAmount',
            sorter: true,
            render: (key, data, index) => { return (<><span className=''>{data?.startDate ? moment(data.startDate).format("DD-MM-YYYY") : "N/A "} - {data?.endDate ? moment(data.endDate).format("DD-MM-YYYY") : "N/A "}</span></>) }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            render: (key, data, index) => { return (<><span className='text-capitalize badge badge-info'>{data?.status ? 'Active' : 'Not Active'}</span></>) }
        },
        {
            title: 'Action',
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            render: (key, data) => {
                return (
                    <Grid className={classes.buttons}>
                        <button className="table-action-button" onClick={() => handleEditUser(data)}>
                            <EditIcon ></EditIcon>
                        </button>
                        <button className="table-action-button" onClick={() => handleDeleteUser(data?._id)}>
                            <DeleteIcon></DeleteIcon>
                        </button>
                    </Grid>
                )
            },
        }

    ];
    return (
        <>
            <DashboardLayout>
                <AppBar breadcrumbs={[{ route: '/coupon', name: "Coupon Management" }]} />
                <button className="add-category-button" onClick={() => { setSave(true) }} >Add Coupon</button>
                <Grid container className="mt-4" >
                    <Navbar title="Coupon Management" />
                    <Card className={classes.card}>
                        {matches &&
                            <Box className={classes.searchBox}>
                                <NavbarSearch />
                            </Box>
                        }
                        <Grid container>
                            <Grid smUp={12} className={classes.tableContainerRow}>
                                <Table className={classes.tableWrapper} scroll bordered columns={columns} dataSource={filterCoupons} onChange={handleChange} />
                                <Pagination current={currentPage} total={totalCount} className="table-pagination" onChange={handlePagination} />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </DashboardLayout>
            <AddCoupon save={save} setSave={setSave} handleClose={handleClose} setList={setList} list={list} />
            <EditCoupon edit={edit} setEdit={setEdit} coupon={coupon} setList={setList} list={list} />
        </>
    )
}
