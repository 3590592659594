const types = {
    CLEAR_STATE: "CLEAR_STATE",
    HANDLE_SIDEBAR_NAVIGATION_STATE: "HANDLE_SIDEBAR_NAVIGATION_STATE",
    HANDLE_SIDEBAR_DRAWER_TOGGLE_STATE: "HANDLE_SIDEBAR_DRAWER_TOGGLE_STATE",

    // Authentication

    API_LOGIN_INITIATE: "API_LOGIN_INITIATE",
    API_LOGIN_SUCCESS: "API_LOGIN_SUCCESS",

    API_LOGOUT_INITIATE: "API_LOGOUT_INITIATE",
    API_LOGOUT_SUCCESS: "API_LOGOUT_SUCCESS",

    API_FORGOT_PASSWORD_INITIATE: "API_FORGOT_PASSWORD_INITIATE",
    API_FORGOT_PASSWORD_SUCCESS: "API_FORGOT_PASSWORD_SUCCESS",

    API_CHANGE_PASSWORD_INITIATE: "API_CHANGE_PASSWORD_INITIATE",
    API_CHANGE_PASSWORD_SUCCESS: "API_CHANGE_PASSWORD_SUCCESS",

    API_RESET_PASSWORD_LINK_INITIATE: "API_RESET_PASSWORD_LINK_INITIATE",
    API_RESET_PASSWORD_LINK_SUCCESS: "API_RESET_PASSWORD_LINK_SUCCESS",

    API_RESET_PASSWORD_INITIATE: "API_RESET_PASSWORD_INITIATE",
    API_RESET_PASSWORD_SUCCESS: "API_RESET_PASSWORD_SUCCESS",

    API_USER_LIST_INITIATE: "API_USER_LIST_INITIATE",
    API_USER_LIST_SUCCESS: "API_USER_LIST_SUCCESS",

    API_USER_BLOCK_INITIATE: "API_USER_BLOCK_INITIATE",
    API_USER_BLOCK_SUCCESS: "API_USER_BLOCK_SUCCESS",

    API_USER_DETAIL_INITIATE: "API_USER_DETAIL_INITIATE",
    API_USER_DETAIL_SUCCESS: "API_USER_DETAIL_SUCCESS",

    API_USER_DETAIL_UPDATE_INITIATE: "API_USER_DETAIL_UPDATE_INITIATE",
    API_USER_DETAIL_UPDATE_SUCCESS: "API_USER_DETAIL_UPDATE_SUCCESS",

    API_USER_DELETE_INITIATE: "API_USER_DELETE_INITIATE",
    API_USER_DELETE_SUCCESS: "API_USER_DELETE_SUCCESS",

    API_DASHBOARD_INITIATE: "API_DASHBOARD_INITIATE",
    API_DASHBOARD_SUCCESS: "API_DASHBOARD_SUCCESS",
    API_DASHBOARD_FAILED: "API_DASHBOARD_FAILED",

    API_USER_MANAGEMENT_FAILED: "API_USER_MANAGEMENT_FAILED",
    API_AUTHENTICATION_FAILED: "API_AUTHENTICATION_FAILED",

    // Category Management

    Api_ORDER_LIST_INITIATE: "Api_ORDER_LIST_INITIATE",
    Api_ORDER_STATUS_UPDATE: "Api_ORDER_STATUS_UPDATE",
    Api_ORDER_DELETE: "Api_ORDER_DELETE",
    Api_ORDER_LIST_SUCCESS: "Api_ORDER_LIST_SUCCESS",
    Api_ORDER_TRASH_LIST_INITIATE : "Api_ORDER_TRASH_LIST_INITIATE",
    Api_ORDER_TRASH_LIST_SUCCESS : "Api_ORDER_TRASH_LIST_SUCCESS",
    Api_ORDER_TRASH_RECOVER : "Api_ORDER_TRASH_RECOVER",

    API_ADD_CATEGORY_INITIATE: "API_ADD_CATEGORY_INITIATE",
    API_ADD_CATEGORY_SUCCESS: "API_ADD_CATEGORY_SUCCESS",

    API_EDIT_CATEGORY_INITIATE: "API_EDIT_CATEGORY_INITIATE",
    API_EDIT_CATEGORY_SUCCESS: "API_EDIT_CATEGORY_SUCCESS",

    API_DELETE_CATEGORY_INITIATE: "API_DELETE_CATEGORY_INITIATE",
    API_DELETE_CATEGORY_SUCCESS: "API_DELETE_CATEGORY_SUCCESS",

    API_CATEGORY_MANAGEMENT_FAILED: "API_CATEGORY_MANAGEMENT_FAILED",
    API_CATEGORY_MANAGEMENT_SUCCESS: "API_CATEGORY_MANAGEMENT_SUCCESS",

    // Need help

    API_NEED_HELP_LIST_INITIATE: "API_NEED_HELP_LIST_INITIATE",
    API_NEED_HELP_LIST_SUCCESS: "API_NEED_HELP_LIST_SUCCESS",

    API_NEED_HELP_MANAGEMENT_FAILED: "API_NEED_HELP_MANAGEMENT_FAILED",

    // Quote management

    API_QUOTE_LIST_INITIATE: "API_QUOTE_LIST_INITIATE",
    API_QUOTE_LIST_SUCCESS: "API_QUOTE_LIST_SUCCESS",

    API_ADD_QUOTE_INITIATE: "API_ADD_QUOTE_INITIATE",
    API_ADD_QUOTE_SUCCESS: "API_ADD_QUOTE_SUCCESS",

    API_EDIT_QUOTE_INITIATE: "API_EDIT_QUOTE_INITIATE",
    API_EDIT_QUOTE_SUCCESS: "API_EDIT_QUOTE_SUCCESS",

    API_DELETE_QUOTE_INITIATE: "API_DELETE_QUOTE_INITIATE",
    API_DELETE_QUOTE_SUCCESS: "API_DELETE_QUOTE_SUCCESS",

    API_QUOTE_MANAGEMENT_FAILED: "API_QUOTE_MANAGEMENT_FAILED",


    API_CONTENT_UPDATE: "API_CONTENT_UPDATE",
    API_GET_CONTENT: "API_GET_CONTENT",
    API_GET_CONTENT_SUCCESS: "API_GET_CONTENT_SUCCESS",

    API_DISCOUNT_UPDATE: "API_DISCOUNT_UPDATE",
    API_GET_DISCOUNT: "API_GET_DISCOUNT",
    API_GET_DISCOUNT_SUCCESS: "API_GET_DISCOUNT_SUCCESS",

    API_GET_COUPON      : "API_GET_COUPON",
    API_ADD_COUPON      : "API_ADD_COUPON",
    API_COUPON_UPDATE   : "API_COUPON_UPDATE",
    API_COUPON_DELETE   : "API_COUPON_DELETE",
    API_COUPON_SUCCESS  : "API_COUPON_SUCCESS",

    API_HOME_UPDATE: "API_HOME_UPDATE",
    API_GET_HOME: "API_GET_HOME",
    API_GET_HOME_SUCCESS: "API_GET_HOME_SUCCESS",

    API_FAQ_ADD: "API_FAQ_ADD",
    API_FAQ_EDIT: "API_FAQ_EDIT",
    API_FAQ_DELETE: "API_FAQ_DELETE",
    API_GET_FAQ: "API_GET_FAQ",
    API_GET_FAQ_SUCCESS: "API_GET_FAQ_SUCCESS",
    API_GET_FAQ_FAILED: "API_GET_FAQ_FAILED",

    API_TESTIMONIAL_ADD: "API_TESTIMONIAL_ADD",
    API_TESTIMONIAL_DELETE: "API_TESTIMONIAL_DELETE",
    API_TESTIMONIAL_EDIT: "API_TESTIMONIAL_EDIT",
    API_GET_TESTIMONIAL: "API_GET_TESTIMONIAL",
    API_GET_TESTIMONIAL_SUCCESS: "API_GET_TESTIMONIAL_SUCCESS",
    API_GET_TESTIMONIAL_FAILED: "API_GET_TESTIMONIAL_FAILED",

    API_POPUP_UPDATE : "API_POPUP_UPDATE",
    API_GET_POPUP : "API_GET_POPUP",
    API_GET_POPUP_SUCCESS : "API_GET_POPUP_SUCCESS",

    UPDATE_LOADER: "UPDATE_LOADER",
}

export default types
