import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux'
import { addCouponAction } from '../../store/actions';



const AddCoupon = ({save, setSave, handleClose,list, setList}) => {
    const dispatch = useDispatch()

    const validationSchema = Yup.object().shape({
        code          : Yup.string().required('Please enter the name'),
    })

    return (
        <>
          <Modal
          show={save}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Coupon</Modal.Title>
          </Modal.Header>
          <Modal.Body>     
            <Formik
              enableReinitialize={true}
              initialValues={{
                discountType        : "fixed",
                discountAmount      : "",
                code                : "",
                startDate           : "",
                endDate             : "",
                minimumAmount       : 0,
                isShowTopBar        : 0,
                oneTimeUsed         : 0
              }}
              validationSchema={validationSchema}
              onSubmit={async (values) =>  {
                dispatch(addCouponAction(values))
                setTimeout( () => {
                  setList(!list)
                  setSave(!save)
                },2000)                
              }}
            >
              {({
                values,
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
              }) => (
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Discount Type</Form.Label> 
                        <Form.Control as="select" name="discountType" value={values?.discountType} onChange={handleChange} onBlur={handleBlur}>
                            <option value="fixed" >Fixed</option>
                            <option value="percentage" >Percentage</option>
                        </Form.Control>   
                        <Form.Label>One time Usage</Form.Label> 
                        <Form.Control as="select" name="oneTimeUsed" value={values?.oneTimeUsed}  onChange={handleChange} onBlur={handleBlur}>                        
                            <option value="0">No</option>
                            <option value="1">Yes</option>
                        </Form.Control>
                        <Form.Label>Discount Amount</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Amount"
                            autoFocus
                            name="discountAmount"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value = {values?.discountAmount}
                        />
                         <Form.Label>Minimum Amount</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Minimum Amount"
                            autoFocus
                            name="minimumAmount"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value = {values?.minimumAmount}
                        />
                    <Form.Label>Coupon Code</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Coupon Code"
                        autoFocus
                        name="code"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value = {values?.code}
                    />      
                    <Form.Label>Coupon Start From</Form.Label>
                    <Form.Control
                        type="date"
                        placeholder="Coupon Start Date"
                        autoFocus
                        name="startDate"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value = {values?.startDate}
                    /> 
                    <Form.Label>Coupon End Date</Form.Label>
                    <Form.Control
                        type="date"
                        placeholder="Coupon End Date"
                        autoFocus
                        name="endDate"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value = {values?.endDate}
                    />            
                    <Form.Label>Is Show Top Bar</Form.Label> 
                    <Form.Control as="select" name="isShowTopBar" value={values?.isShowTopBar}  onChange={handleChange} onBlur={handleBlur}>                        
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                    </Form.Control>                   
                </Form.Group>                
                  <Button type='submit' className='mr-2 btn-action'>Add Coupon</Button>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>                  
                </Form>
              )}
            </Formik>      
          </Modal.Body>
        </Modal> 
        </>
    )

}
export default AddCoupon;
