import { takeLatest, call, put, take } from "redux-saga/effects";
import types from '../types/index'
import api from "../../apiKit/api";
import { toast } from "react-toastify";
import { appConstants } from '../../themes/constants'


function* getAllCouponsSaga({payload}) { 
    try {
        let response = yield call(api.couponFetchApi, payload);     
        if (response?.status >= 200 && response?.status < 400) yield put({ type: types.API_COUPON_SUCCESS, result: response.data });
        else {
            toast.error(response?.message, { toastId: "err" });
        }
    }
    catch (error) {
        toast.error(appConstants.axiosConstants.messageStatus500, { toastId: "err" });
    }
}

function* addCouponFuction({payload}) {
    try {
        yield put({ type: types.UPDATE_LOADER, payload: true })
            
        let response = yield call(api.couponAddApi, payload);

        yield put({ type: types.UPDATE_LOADER, payload: false })
        if (response?.status >= 200 && response?.status < 400) {
            toast.success(response?.message);
        }
        else {
            toast.error(response?.message, { toastId: "err" });
            yield put({ type: types.API_GET_COUPON_FAILED, });
        }
    }
    catch (error) {
        yield put({ type: types.UPDATE_LOADER, payload: false })
        toast.error(appConstants.axiosConstants.messageStatus500, { toastId: "err" });
        yield put({ type: types.API_GET_COUPON_FAILED, });
    }
}

function* editCouponFuction({payload}) {
    try {
        yield put({ type: types.UPDATE_LOADER, payload: true })
        let response = yield call(api.couponEditApi, payload);
        yield put({ type: types.UPDATE_LOADER, payload: false })
        if (response?.status >= 200 && response?.status < 400) {
            toast.success(response?.message);
        }
        else {
            toast.error(response?.message, { toastId: "err" });
            yield put({ type: types.API_GET_COUPON_FAILED, });
        }
    }
    catch (error) {
        yield put({ type: types.UPDATE_LOADER, payload: false })
        toast.error(appConstants.axiosConstants.messageStatus500, { toastId: "err" });
        yield put({ type: types.API_GET_COUPON_FAILED, });
    }
}

function* deleteCouponFuction({payload}) {
    try {       
        yield put({ type: types.UPDATE_LOADER, payload: true })
        let response = yield call(api.couponDeleteApi, payload);
        yield put({ type: types.UPDATE_LOADER, payload: false })
        if (response?.status >= 200 && response?.status < 400) {
            toast.success(response?.message);
        }
        else {
            toast.error(response?.message, { toastId: "err" });
            yield put({ type: types.API_GET_COUPON_FAILED, });
        }
    }
    catch (error) {
        yield put({ type: types.UPDATE_LOADER, payload: false })
        toast.error(appConstants.axiosConstants.messageStatus500, { toastId: "err" });
        yield put({ type: types.API_GET_COUPON_FAILED, });
    }
}

export default function* rootCouponManagementSaga() {
    yield takeLatest(types.API_GET_COUPON, getAllCouponsSaga);    
    yield takeLatest(types.API_ADD_COUPON, addCouponFuction);
    yield takeLatest(types.API_COUPON_UPDATE, editCouponFuction);
    yield takeLatest(types.API_COUPON_DELETE, deleteCouponFuction);
}
