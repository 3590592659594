import React, { useState, useEffect, useRef } from "react";
import "antd/dist/antd.css";
import "../dashboard/dashboard.scss";
import { Table, Button as AntButton, Pagination, Modal } from "antd";
import { Card } from "react-bootstrap";
import clsx from "clsx";
import { Grid, useTheme, useMediaQuery, Box } from "@material-ui/core";
import DashboardLayout from "../../layouts/dashboardLayout";
import { AppBar, Navbar, NavbarSearch } from "../../customComponents";
import { appConstants } from "../../themes/constants";
import { useStyles } from "../../styles/styles";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { TrashOrderListingAction, TrashOrderRecoverAction } from "../../store/actions";
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';


export const TrashOrder = () => {
    const styleProps = { searchable: true };
    const classes = useStyles(styleProps);
    const theme = useTheme();
    const dispatch = useDispatch();
    const matches = useMediaQuery(theme.breakpoints.down("xs"));
    const { trashOrderList } = useSelector((state) => state.orderManagement);  
    const pagination = useSelector((x) => x.orderManagement.pagination);
    const [currentPage, setCurrentPage] = useState(1);
    const [ list, setList ] = useState(false)
    const [payload, setPayload] = useState({
        offset: 0,
        limit: 10,
        sort: "",
        order: 0,
        search: "",
    });
    
   
    useEffect( () => {
        dispatch(TrashOrderListingAction(payload))
        
    }, [])


    const columns = [
        {
          title: "Sr. No.",
          dataIndex: "sr",
          key: "sr",
          ellipsis: false,
          render: (key, data, index) => (
            <>
              {payload.offset + index + 1}{" "}
             
            </>
          ),
        },
        {
          title: "Order Id",
          dataIndex: "_id",
          key: "_id",
          ellipsis: false,
          render: (key, data, index) => {
            return (
              <>
                <span>#{data._id}</span>
              </>
            );
          },
        },
        {
          title: "Username",
          dataIndex: "user.name",
          key: "user.name",
    
          ellipsis: false,
          render: (key, data, index) => {
            return (
              <>
                <span>{data?.user?.name}</span>
              </>
            );
          },
        },
        {
          title: "Email Address",
          dataIndex: "user.address.email",
          key: "user.email",
          ellipsis: false,
          render: (key, data, index) => {
            return (
              <>
                <span>{data?.address?.email}</span>
              </>
            );
          },
        },
       
        {
          title: "Order Time",
          dataIndex: "createdAt",
          key: "createdAt",
          ellipsis: false,
          render: (key, data, index) => {
            return (
              <>
                <span>{moment(data?.createdAt).format("DD-MM-YYYY HH:mm")}</span>
              </>
            );
          },
        },
        {
          title: "Price",
          dataIndex: "totalPrice",
          key: "totalPrice",
          ellipsis: false,
          render: (key, data, index) => {
            return (
              <>
                <span>{`₹${data?.totalPrice}`}</span>
              </>
            );
          },
        },
        {
          title: "Payment Mode",
          dataIndex: "paymentType",
          key: "paymentType",
          ellipsis: false,
          render: (key, data, index) => {
            return (
              <>
                <span className="badge badge-secondary">
                  {data?.paymentType == "online" ? "PhonePe" : "COD"}
                </span>
              </>
            );
          },
        },
        {
          title: "Frames",
          dataIndex: "cart",
          key: "cart",
          ellipsis: false,
          render: (key, data, index) => {
            return (
              <>
                <span>{data?.cart?.length}</span>
              </>
            );
          },
        },
        {
            title: "Delete At",
            dataIndex: "isDeleted",
            key: "isDeleted",
            ellipsis: false,
            render: (key, data, index) => {
              return (
                <>
                  <span>{data?.isDeleted}</span>
                </>
              );
            },
        },
       
        {
          title: "Actions",
          dataIndex: "action",
          key: "Action",
          align: "center",
          render: (key, data) => {
            return (
                <Grid className={classes.buttons}>                                             
                    <button className="table-action-button" title="Restore" onClick={() => handleRecoverOrder(data?._id)}>
                        <RestoreIcon ></RestoreIcon>
                    </button>
                    {/* <button className="table-action-button" >
                        <DeleteIcon></DeleteIcon>
                    </button> */}
                </Grid>
            )
          },
        },
    ];

    const handleRecoverOrder = (id) => {
        const data = {
            id : id
        }
       
         dispatch(TrashOrderRecoverAction(data))
        setTimeout( () => {
            setList(!list)
        }, 2500)
    }   

    const handlePagination = (pagination) => {
        setCurrentPage(pagination)
        const tempPayload = {
            ...payload,
            offset: pagination == 1 ? 0 : (pagination - 1) * 10,
        };
        setPayload(tempPayload);
        dispatch(TrashOrderListingAction(tempPayload));
        window.scrollTo(0, 0);
    };

    const handleChange = (pagination, filters, sorter) => {
        const tempPayload = {
            ...payload,
            sort: sorter?.columnKey,
            order:
            sorter.order === "ascend" ? 1 : sorter.order === "descend" ? -1 : null,
            offset: currentPage === 1 ? 0 : (currentPage - 1) * 10,
        };
        setPayload(tempPayload);
        dispatch(TrashOrderListingAction(tempPayload));
    };

    return ( <>
    <DashboardLayout>
        <AppBar
            breadcrumbs={[{ route: "trash-orders", name: "Order Mangement ( Trash Orders )" }]}
        />
        <Navbar title="Trash Orders" />
        <Card className={classes.card}>
        {matches && (
          <Box className={classes.searchBox}>
            <NavbarSearch />
          </Box>
        )}
         <Grid container>
          <Grid smUp ={12} className={classes.tableContainerRow}>           

            <Table
              className={classes.tableWrapper}
              scroll
              bordered
              columns={columns}
              dataSource={trashOrderList}
              onChange={handleChange}
            />
            <Pagination
              current={currentPage}
              total={pagination?.totalCount ? pagination.totalCount : 10}
              onChange={handlePagination}
              className="table-pagination"
            />
          </Grid>
        </Grid>
        </Card>
    </DashboardLayout>
    </>)
}