import JSZip from "jszip";
import { base_URL } from "../apiKit/apikit";
let count = 1;

export const downloadImagesAsZip = async (items, customer = "products", keys) => {
  try {
    const zip = new JSZip();

    for (const item of items) {
      for (const key of keys) {
        const imgName = item?.[key]?.replace(/.*[\\\/]/, ""); // Extract file name from path
        const response = await fetchImage(imgName, customer);
        const uniqueFileName = getUniqueFileName(zip, imgName, key);
        const fileBlob = await response.blob();
        zip.file(uniqueFileName, fileBlob);
      }
    }

    await downloadZip(zip);
  } catch (error) {
    console.error("Error downloading images:", error);
    throw error;
  }
};

const fetchImage = async (imgName, customer) => {
  const response = await fetch(`${base_URL}admin/send-file?imageName=${imgName}`, {
    method: 'GET',
    headers: {
      'customer': customer,
    },
  });

  if (!response.ok) {
    throw new Error(`Failed to fetch image (${imgName}). Status: ${response.status}`);
  }

  return response;
};

const getUniqueFileName = (zip, originalName, key) => {
  let fileName = originalName;

    const [name, extension] = fileName.split('.'); // Use fileName instead of originalName
    fileName = `customer_${key}_${count}.${extension}`;
    count++;

  return fileName;
};

const downloadZip = async (zip) => {
  const zipBlob = await zip.generateAsync({ type: "blob" });
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(zipBlob);
  downloadLink.download = "images.zip";
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};
