import types from "../types/index";
const initialState = {
    isLoading: false,
    data: null,
};
export const popupManagementReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.API_GET_POPUP: return { ...state, isLoading: true };
        case types.API_GET_POPUP_SUCCESS: return { ...state, isLoading: false, data: action?.result?.data };
        default: return { ...state };
    }
}