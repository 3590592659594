import React, { useState, useEffect, useRef } from "react";
import { saveAs } from "file-saver";
import "antd/dist/antd.css";
import "./dashboard.scss";
import { Table, Button as AntButton, Pagination, Modal } from "antd";
import { Card, } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"
import clsx from "clsx";
import { Grid, useTheme, useMediaQuery, Box } from "@material-ui/core";
import DashboardLayout from "../../layouts/dashboardLayout";
import {
  AppBar,
  Navbar,
  NavbarSearch,
} from "../../customComponents";
import { appConstants } from "../../themes/constants";
import { useStyles } from "../../styles/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  OrderListingAction, OrderStatusUpdateAction, orderDeleteAction,
} from "../../store/actions";
import moment from "moment";
import { toast } from "react-toastify";
import JSZip from "jszip";
import JSZipUtils from 'jszip-utils';
import { base_URL } from "../../apiKit/apikit";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getImage } from "../../fileUploader/getImage";
import { downloadImagesAsZip } from "../../fileUploader/zipDownloader";

export const orderStatusEnum = {
  pending: 'pending',
  inProgress: 'inProgress',
  delivered: 'delivered'
}


export const OrderListing = () => {
  const styleProps = { searchable: true };
  // Hooks declarations
  const classes = useStyles(styleProps);
  const theme = useTheme();
  const dispatch = useDispatch();
  const { orderList } = useSelector((state) => state.orderManagement);
  const pagination = useSelector((x) => x.orderManagement.pagination);
  const [currentPage, setCurrentPage] = useState(1);
  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  const baseUrl = "https://s3.amazonaws.com/static.neostack.com/img/react-slick/"
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [viewData, setViewData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [payload, setPayload] = useState({
    offset: 0,
    limit: 10,
    sort: "",
    order: 0,
    search: "",
  });
  //Images destructuring

  //Constants
const handleMail=async (id)=>{
  try {
    const uploadUrl = `${base_URL}admin/status-update/${id}`;
    let resp = await fetch(uploadUrl, {
        method: 'GET',
        
    });

    // Check if the response is valid JSON
    if (resp.ok) {
        const url = await resp.json();
        return url;
    } else {
        // Handle non-JSON response
        console.error('Server responded with error:', resp.status, resp.statusText);
        return null;
    }
} catch (error) {
    console.error('Error during fetch:', error.message);
    return null;
}
}
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  //lifecycle hooks
  useEffect(() => {
    document.title = appConstants.title;
    // dispatch(handleNavigationStateAction(1, false))
    dispatch(OrderListingAction(payload));
  }, []);
useEffect(()=>{
},[viewData])
  const handlePagination = (pagination) => {
    setCurrentPage(pagination)
    const tempPayload = {
      ...payload,
      offset: pagination == 1 ? 0 : (pagination - 1) * 10,
    };
    setPayload(tempPayload);
    dispatch(OrderListingAction(tempPayload));
    window.scrollTo(0, 0);
  };

  const handleChange = (pagination, filters, sorter) => {
    const tempPayload = {
      ...payload,
      sort: sorter?.columnKey,
      order:
        sorter.order === "ascend" ? 1 : sorter.order === "descend" ? -1 : null,
      offset: currentPage === 1 ? 0 : (currentPage - 1) * 10,
    };
    setPayload(tempPayload);
    dispatch(OrderListingAction(tempPayload));
  };


  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };


  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "sr",
      key: "sr",
      ellipsis: false,
      render: (key, data, index) => <>{payload.offset + index + 1}</>,
    },
    {
      title: "Order Id",
      dataIndex: "_id",
      key: "_id",
      ellipsis: false,
      render: (key, data, index) => {
        return (
          <>
            <span>#{data._id}</span>
          </>
        );
      },
    },
    // {
    //   title: "Transaction Id",
    //   dataIndex: "orderId",
    //   key: "orderId",
    //   ellipsis: false,
    //   render: (key, data, index) => {
    //     return (
    //       <>
    //         <span>{data.orderId}</span>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Username",
      dataIndex: "user.name",
      key: "user.name",

      ellipsis: false,
      render: (key, data, index) => {
        return (
          <>
            <span>{data?.user?.name}</span>
          </>
        );
      },
    },
    {
      title: "Email Address",
      dataIndex: "user.address.email",
      key: "user.email",
      ellipsis: false,
      render: (key, data, index) => {
        return (
          <>
            <span>{data?.user?.address?.email}</span>
          </>
        );
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      ellipsis: false,
      render: (key, data, index) => {
        return (
          <>
            <div style={{ textWrap: "wrap", width: "170px" }}>
              <strong>Phone :</strong> {data?.address?.phone ?? "--"}, <br></br>
              <strong>City :</strong> {data?.address?.city ?? "--"}, <br></br>
              <strong>Pincode :</strong> {data?.address?.pincode ?? "--"}, <br></br>
              <strong>State : </strong>{data?.address?.state ?? "--"}, <br></br>
              <strong>Country :</strong> {data?.address?.country ?? "--"}, <br></br>
              <strong>Street  :</strong> {data?.address?.street ?? "--"} <br></br>
            </div>
          </>
        );
      },
    },
    {
      title: "Order Time",
      dataIndex: "createdAt",
      key: "createdAt",
      ellipsis: false,
      render: (key, data, index) => {
        return (
          <>
            <span>{moment(data?.createdAt).format("DD-MM-YYYY HH:mm")}</span>
          </>
        );
      },
    },
    {
      title: "Price",
      dataIndex: "totalPrice",
      key: "totalPrice",
      ellipsis: false,
      render: (key, data, index) => {
        return (
          <>
            <span>{`₹${data?.totalPrice}`}</span>
          </>
        );
      },
    },
    {
      title: "Payment Mode",
      dataIndex: "paymentType",
      key: "paymentType",
      ellipsis: false,
      render: (key, data, index) => {
        return (
          <>
            <span className="badge badge-secondary">{data?.paymentType == 'online' ? 'PhonePe' : 'COD'}</span>
          </>
        );
      },
    },
    {
      title: "Frames",
      dataIndex: "cart",
      key: "cart",
      ellipsis: false,
      render: (key, data, index) => {
        return (
          <>
            <span>{data?.cart?.length}</span>
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ellipsis: false,
      render: (key, data, index) => {
        return (
          <>
            <span>{data?.status}</span>
          </>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "Action",
      align: "center",
      render: (key, data) => {
        return (
          <Grid className={classes.buttons}>
            <button
              className="table-action-button"
              onClick={() => {
                setIsModalVisible(true)
                let items = []
                data?.cart?.forEach(async (item) => {
                  // let url = getImage
                  let url = await getImage(item?.frame,"products")
                  
                  setViewData((prev)=> [...prev,url])
                })
              }
              }
            >
              {"view"}
            </button>
            <button className="table-action-button" onClick={() => handleDownload(data?._id)}  >
              Invoice
            </button>
            <button
              className="table-action-button"
              onClick={() => downloadImage(data?.cart)}
            >
              {"download"}
            </button>
            {data?.status !== orderStatusEnum.delivered && <button
              className="table-action-button2"
              onClick={() => {
                debugger

                if (data?.status === orderStatusEnum.pending) {
                  dispatch(OrderStatusUpdateAction({
                    id: data?._id,
                    status: orderStatusEnum.inProgress
                  },
                    payload))
                }
                else {
                  dispatch(OrderStatusUpdateAction({
                    id: data?._id,
                    status: orderStatusEnum.delivered
                  }, payload))
                }
                handleMail(data?._id)
              }}
            >
              Change to {data?.status === orderStatusEnum.pending ? orderStatusEnum.inProgress : orderStatusEnum.delivered}
            </button>}

            <button
              className="table-action-button3"
              onClick={() => {
                setSelectedValue(data?._id)
                setModal(true)
              }}
            >
              {"Delete"}
            </button>
          </Grid>
        );
      },
    },
  ];

//   async function downloadImage(cart) {
//     var zip = new JSZip();
//     var count = 0;
//     var zipFilename = "frames.zip";
//     let images=[]
//     cart.forEach(async function (item, i) {


      

// Object.keys(item).forEach(async (e)=>{
//   images.push(await getImage(item[e]))
// })

//       // var filename = item.frame;
//       var filename = item.frame;
//       filename = filename.replace(/[\/\*\|\:\<\>\?\"\\]/gi, '').replace("httpssequenceimagestaging.blob.core.windows.netretouch", "");
//       JSZipUtils.getBinaryContent(item.frame, function (err, data) {
//         if (err) {
//           throw err;
//         }
//         zip.file(filename, data, { binary: true });
//         var filename2 = item.picture;
//         filename2 = filename2.replace(/[\/\*\|\:\<\>\?\"\\]/gi, '').replace("httpssequenceimagestaging.blob.core.windows.netretouch", "");
//         JSZipUtils.getBinaryContent(item.picture, function (err2, data2) {
//           if (err2) {
//             throw err2;
//           }
//           zip.file(filename2, data2, { binary: true });

//           var filename3 = item.original_image;
//           filename3 = filename3.replace(/[\/\*\|\:\<\>\?\"\\]/gi, '').replace("httpssequenceimagestaging.blob.core.windows.netretouch", "");
//           JSZipUtils.getBinaryContent(item.original_image, function (err3, data3) {
//             if (err3) {
//               throw err3;
//             }
//             zip.file(filename3, data3, { binary: true });
//             count++;
//             if (count == cart.length) {
//               zip.generateAsync({ type: 'blob' }).then(function (content) {
//                 saveAs(content, zipFilename);
//               });
//             }
//           });

//         });

//       });

//     });
//   }
// async function downloadImage(cart) {
//   var zip = new JSZip();
//   var count = 0;
//   const keys = ["frame", "original_image", "picture"];
//   var zipFilename = "frames.zip";
//   let images = [];

//   try {
//     for (const item of cart) {
//       for (const e of keys) {
//         images.push(await getImage(item[e],"products"));
//       }
//     }

//     // Wait for all promises in the images array to resolve
//     images = await Promise.all(images);

//     // Now, you can add the images to the zip file
//     images.forEach((image, index) => {
//       zip.file(`customer_${keys[index]}.jpg`, image);
//     });

//     // Generate the zip file
//     const content = await zip.generateAsync({ type: "blob" });

//     // Trigger the download
//     saveAs(content, zipFilename);
//   } catch (error) {
//     console.error("Error while processing images:", error);
//   }
// }
async function downloadImage(cart) {
  const keys = ["frame", "original_image", "picture"];

  downloadImagesAsZip(cart,"products",keys)
}

// Function to extract file extension from a URL
function extractFileExtension(url) {
  const match = url.match(/\.([^.]+)$/);
  return match ? match[1] : null;
}



  const handleDownload = async (orderId) => {
    try {
      const response = await axios.post(`${base_URL}/admin/export-pdf`, { orderId }, {
        responseType: 'blob',
      });
      
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'invoice.pdf';
      link.click();
    }
    catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  const handleDownloadCsv = async () => {
    const Payload = {
      startDate,
      endDate,
    };
    try {
      const response = await axios.post(
        `${base_URL}/admin/export-order`,
        { Payload },
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "orders.csv";
      link.click();
    } catch (error) {
      console.error("Error downloading data:", error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <DashboardLayout>
      
      <AppBar
        breadcrumbs={[{ route: "Order-Listing", name: "Order Mangement" }]}
      />
      <Navbar title="Orders Mangement" />
      <Card className={classes.card}>
        {matches && (
          <Box className={classes.searchBox}>
            <NavbarSearch />
          </Box>
        )}
        <Grid container>
          <Grid smUp={12} className={classes.tableContainerRow}>
            <div
              className="main_div d-flex align-items-center"
              style={{ gap: "0 10px" }}
            >
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                className={"form-control"}
                onChange={handleStartDateChange}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                placeholderText="Start Date"
              />
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={endDate}
                className={"form-control"}
                onChange={handleEndDateChange}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                placeholderText="End Date"
              />
              <button
                className={
                  "m-0" + clsx([classes.authButton, classes.filledButton])
                }
                onClick={handleDownloadCsv}
                style={{ marginBottom: "3px" }}
              >
                Export Data
              </button>
            </div>
            <Table
              className={classes.tableWrapper}
              scroll
              bordered
              columns={columns}
              dataSource={orderList}
              onChange={handleChange}
            />
            <Pagination
              current={currentPage}
              total={pagination?.totalCount ? pagination.totalCount : 10}
              onChange={handlePagination}
              className="table-pagination"

            />
          </Grid>
        </Grid>
      </Card>

      {isModalVisible && <div style={{
        position: 'fixed',
        width: '81%',
        height: '100%',
        top: 0,
        zIndex: 9999,
        backgroundColor: '#00000055',
        overflowY: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
        onClick={(e) => {
          e.stopPropagation()
          setIsModalVisible(false)
          setViewData([])

        }}>
        <div style={{
          width: 400,
          height: 400,
          backgroundColor: 'white'
        }} onClick={(e) => {
          e.stopPropagation()
        }}>
          <Slider {...settings}>
            {viewData?.map((v, i) => {
              console.log(v,"vvvvv")
              // let url = URL.createObjectURL(v)
              return (
                <img key={i} style={{ padding: 10 }} src={v
                } />
              )
            })
            }

          </Slider>
        </div></div>}

      <Modal
        visible={modal}
        title="Delete Order"
        closable={false}
        onCancel={() => {
          setSelectedValue(null)
          setModal(false)
        }}
        centered={true}
        footer={[
          <div className="delete-category-modal-footer">
            <button style={{ marginRight: 8 }} onClick={() => {
              setModal(false)
              dispatch(orderDeleteAction({ id: selectedValue }, payload))
              setSelectedValue(null)
            }}>Delete</button>
            <button onClick={() => {
              setSelectedValue(null)
              setModal(false)
            }}>Cancel</button>
          </div>
        ]}

        className="delete-category-modal"
      >
        <div className="delete-category-modal-text">Are you sure to delete this order?</div>
      </Modal>
    </DashboardLayout>
  );
};
